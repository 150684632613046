








































































































import {
  SfList,
  SfLink,
  SfFooter,
  SubscribeForm
} from '~/components';

import {
  defineComponent,
  computed
} from '@nuxtjs/composition-api';

import { useConfigStore } from "~/stores/config";

import { useWebsiteCode } from '~/composables';

export default defineComponent({
  name: 'AppFooter',
  components: {
    SubscribeForm,
    SfFooter,
    SfList,
    SfLink,
  },
  setup() {
    const { storeConfig } = useConfigStore();
    const websiteCode = computed(() => storeConfig.website_code);
    const { isAu } = useWebsiteCode();

    const links = {
      worldOfTogas: [
        { title: 'About Us', url: '/about-us' },
        { title: 'Corporate Responsibility', url: '/csr' },
        { title: 'Quality Control', url: '/quality-control' },
        { title: 'Blog', url: isAu.value ? 'https://togas.com/int/blog/' : '/blog', onlyForWebsites: ['togas_int_website', 'togascom_me'] },
        { title: 'Find Our Location', url: '/store-locator', onlyForWebsites: ['togas_at_website'] },
        { title: 'Contact us', url: '/contact-us', onlyForWebsites: ['togas_at_website'] },
      ],
      customerCare: [
        { title: 'Contact Us', url: '/contact-us',  onlyForWebsites: ['togas_int_website', 'togascom_me'] },
        { title: 'Find Our Location', url: '/store-locator',  onlyForWebsites: ['togas_int_website', 'togascom_me'] },
        { title: 'Shipping and Returns', url: '/customer-care/shipment-returns' },
        { title: 'Impressum', url: '/customer-care/impressum', onlyForWebsites: ['togas_at_website'] },
        { title: 'Terms and Conditions', url: '/customer-care/terms-conditions', onlyForWebsites: ['togas_at_website'] },
        { title: 'Loyalty Program', url: '/customer-care/loyalty-program' },
        { title: 'Blog', url: isAu.value ? 'https://togas.com/int/blog/' : '/blog', onlyForWebsites: ['togas_at_website']  },
      ],
      services: [
        { title: 'Gift Packing', url: '/customer-care/gift-packing' },
      ],
    };

    return {
      links,
      websiteCode,
      isAu
    };
  },
});
